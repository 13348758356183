import 'jspdf-autotable'
import { jsPDF } from "jspdf"
import Loading from '@/components/widgets/Loading.vue'

export default {
  data() {
    return {
      loading: false,
      buyer: {
        name: 'SANGTHAI INDUSTRY CO., LTD.',
        address: '94/43 หมู่ 4 เศรษฐกิจ 1-บางปลา ต.บ้านเกาะ อ.เมือง จ.สมุทรสาคร',
        taxno: '12345678910',
        tel: '0-3483-0032-7, 0-3483-0289',
        fax: '0-3483-0028'
      },
      document: {
        no: 'QT00012333556',
        date: '25/09/2019',
        condition: '',
        method: 'โอนเงินเข้าบัญชี ธ.กรุงเทพ',
        credit: '30 วัน',
        duedate: '25/09/2022'
      },
      debtor: {
        code: 'DT-0001',
        name: 'เกริกวิทย์ ผลดี',
        address: 'บริษัท อเมริกันแสตนด์ดาร์ท จำกัด (มหาชน) เลขที่ 72 ถ.แจ้งวัฒนะ ต.ตลาดบางเขน อ.หลักสี่ กรุงเทพฯ รหัสไปรษณีย์ 10210',
        taxno: '3120600229355A',
        tel: '0-3483-0032-7'
      },
      products: [
        {
          _id: '61837be876a431b5936932ba',
          code: 'P-001',
          name: 'สินค้า1',
          disc: 250,
          price: 2500,
          qty: 2,
          total: 5000
        },
        {
          _id: '61837be876a431b5936932bb',
          code: 'P-002',
          name: 'สินค้า2',
          disc: 0,
          price: 2500,
          qty: 2,
          total: 5000
        },
        {
          _id: '61837be876a431b5936932bb',
          code: 'P-003',
          name: 'สินค้า3',
          disc: 0,
          price: 2500,
          qty: 2,
          total: 5000
        },
        {
          _id: '61837be876a431b5936932bb',
          code: 'P-004',
          name: 'สินค้า4',
          disc: 0,
          price: 2500,
          qty: 2,
          total: 5000
        }
      ],
      note: 'กรุณาโอนเงินเข้าบัญชี ธนาคารไทยพาณิชย์ ชื่อบัญชี สมกานดา ศุภชลาลัย เลขที่บัญชี xxxxxxxxxx สาขา พีทีที คริสตัล',
      discount: 150,
      nettotal: 0,
      afterDisc: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData()
    })
  },
  components: {
    Loading
  }, 
  methods: {
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const doc = new jsPDF('p','mm','a4')
        await this.onInitDocument(doc)
        let element = document.getElementById('receipt-tax-invoice')
        if (element) {
          element.setAttribute("src", doc.output('bloburl'))
          setTimeout(() => {
            this.loading = false
            this.onIframePrint()
          }, 1500)
        }
      }, 1000)
    },
    onIframePrint() {
      let iFrame = document.getElementById("receipt-tax-invoice")
      iFrame.focus()
      iFrame.contentWindow.print()
    },
    onInitDocument(doc) {
        return new Promise(async(resolve, reject) => {
        try {
          doc.addFileToVFS(
            'THSarabunNew.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunNormal
          )
          // add custom font to file
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
          doc.setFont('THSarabunNew', 'normal')

          doc.addFileToVFS(
            'THSarabunNew Bold.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunBold
          )
          // add custom font to file
          doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
          doc.setFont('THSarabunNew', 'bold')

          const head = await this.onInitHeader()
          const body = await this.onInitBody(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 0, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: head,
            body: body,
            willDrawCell (data) {
              if (data.cell.raw && data.section === 'head') {
                if (data.cell.raw.content === 'breaklineheader') {
                  const width = doc.internal.pageSize.getWidth() - 16
                  data.doc.setLineWidth(0.2)
                  data.doc.setDrawColor(100, 116, 139)
                  data.doc.line(data.cell.x, data.cell.y + 5, data.cell.x + width, data.cell.y + 5)
                }
              }
            }
          })
          const sub_footer = await this.onInitSubFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: sub_footer
          })

          const footer = await this.onInitFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: { },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'bottom',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: footer,
            willDrawCell (data) {
              if (data.cell.raw && data.section === 'foot') {
                if (data.cell.raw.content === 'issuer') {
                  data.doc.setLineWidth(0.25)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 25, data.cell.y + 20, data.cell.x + 75, data.cell.y + 20)
                }
                if (data.cell.raw.content === 'approved' ) {
                  data.doc.setLineWidth(0.25)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 25, data.cell.y + 20, data.cell.x + 75, data.cell.y + 20)
                }
              }
            }
          })
          resolve(doc)
        } catch (error) {
          reject(error)
        }
      })
    },
    onInitData() {
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index]
        element.total = (element.qty * element.price) - element.disc
        this.nettotal += (element.qty * element.price) - element.disc
      }
      this.afterDisc = this.nettotal - this.discount
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: 'ใบเสร็จรับเงิน / ใบกำกับภาษี',
              colSpan: 9,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'Receipt',
              colSpan: 9,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 22,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: this.buyer.name,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เลขที่เอกสาร:',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.document.no,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: this.buyer.address,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'วันที่:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.document.date,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `เลขประจำตัวผู้เสียภาษี: ${this.buyer.taxno}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'วิธีการชำระเงิน:',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.document.method,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ติดต่อ: ${this.buyer.tel}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'วันที่ชำระเงิน:',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.document.duedate,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `Fax: ${this.buyer.fax}`,
              colSpan: 9,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'breaklineheader',
              colSpan: 9,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [255, 255, 255],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ลูกค้า: ${this.debtor.name}`,
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                    top: 2
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: `เลขประจำตัวผู้เสียภาษี: ${this.debtor.taxno}`,
              colSpan: 4,
              styles: {
                lineWidth: 0,
                overflow: 'visible',
                cellWidth: 'wrap',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: this.debtor.address,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 5
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: `ติดต่อ: ${this.debtor.tel}`,
              colSpan: 3,
              styles: {
                lineWidth: 0,
                overflow: 'visible',
                cellWidth: 'wrap',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 5
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: 'ลำดับ',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'รหัสสินค้า',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: 'รายการ',
              colSpan: 3,
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'จำนวน',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'ราคา/หน่วย',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'ส่วนลด',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'จำนวนเงิน',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitBody(doc) {
      return new Promise(async (resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 18
        const portion = width / 9
        const fraction = portion / 2
        const halfTwo = portion + fraction
        const data = this.products          
        const body = []
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let obj = {}
          obj = [
            {
              content: index + 1,
              styles: {
                cellWidth: portion / 1.5,
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: element.code,
              styles: {
                cellWidth: portion + (fraction / 2),
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: element.name,
              colSpan: 3,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellWidth: 'auto',
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.$options.filters.numberFormat(element.qty),
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellWidth: portion,
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.$options.filters.numberFormat(element.price),
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                cellWidth: portion + (fraction / 2),
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.$options.filters.numberFormat(element.disc),
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                cellWidth: portion,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.$options.filters.numberFormat(element.total),
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                cellWidth: halfTwo,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ]
          body.push(obj)
        }

        let emptyBody = {}
        emptyBody = [
          {
            content: '',
            styles: {
              cellWidth: portion / 1.4,
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'middle'
            }
          },
          {
            content: '',
            styles: {
              cellWidth: portion + (fraction / 2),
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'middle'
            }
          },
          {
            content: '',
            colSpan: 3,
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 'auto',
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'left',
              valign: 'middle'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion + 5,
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 1,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 1,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          }
        ]

        if (body.length <= 10) {
          const number = 10 - body.length
          for (let index = 0; index < number; index++) {
            body.push(emptyBody)
          }
        }

        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 20
        const portion = width / 2

        const footer = []
        const signer_line = [
          {
            content: `issuer`,
            styles: {
              cellWidth: portion,
              fontSize: 15,
              textColor: [255, 255, 255],
              cellPadding: {
                top: 15
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          },
          {
            content: `approved`,
            styles: {
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [255, 255, 255],
              cellPadding: 0,
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          }
        ]
        footer.push(signer_line)

        const signer_style = {
          cellWidth: 'auto',
          fontSize: 15,
          textColor: [15, 23, 42],
          cellPadding: 1,
          fontStyle: 'normal',
          halign: 'center',
          valign: 'bottom'
        }
        const signer_name = [
          {
            content: 'ผู้จ่ายเงิน',
            styles: signer_style
          },
          {
            content: 'ผู้รับเงิน',
            styles: signer_style
          }
        ]
        footer.push(signer_name)
        
        const signer_date = [
          {
            content: `( ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            styles: signer_style
          },
          {
            content: `( ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            styles: signer_style
          }
        ]
        footer.push(signer_date)
        resolve(footer)
      })
    },
    onInitSubFooter(doc) {
      return new Promise((resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 18
        const portion = width / 9
        const fraction = portion / 2
        const halfTwo = portion + fraction
        
        const footer = []
        const total = [{
            content: this.note,
            colSpan: 5,
            rowSpan: 5,
            styles: {
              lineWidth: 0.1,
              cellPadding: 2,
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'left',
              valign: 'bottom'
            }
          },
          {
            content: 'รวมเงิน',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion + (fraction / 2) + portion,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.nettotal),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(total)

        const discount = [
          {
            content: 'ส่วนลด',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.discount),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(discount)

        const afterDisc = [
          {
            content: 'มูลค่าหลังหักส่วนลด',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.afterDisc),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(afterDisc)

        const excluded = [
          {
            content: 'มูลค่าที่คำนวณภาษี',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.excludeVATFromPrice(this.afterDisc, 7).toFixed(2),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(excluded)

        const valueAddedTax = [
          {
            content: 'ภาษีมูลค่าเพิ่ม 7%',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.getVATAmount(this.afterDisc, 7).toFixed(2),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(valueAddedTax)

        const summary = [
          {
            content: `(${this.formatAsThaiBaht(this.afterDisc, false)})`,
            colSpan: 5,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'italic',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: 'ยอดรวมสุทธิ',
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: this.$options.filters.numberFormat(this.afterDisc),
            colSpan: 2,
            styles: {
              lineWidth: 0.1,
              fillColor: [243, 244, 246],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: halfTwo,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        footer.push(summary)
        resolve(footer)
      })
    }
  }
}
