import 'jspdf-autotable'
import { jsPDF } from "jspdf"
import Loading from '@/components/widgets/Loading.vue'
import { DOCUMENT_FORM_TYPE } from '../../../mixins/enum'

export default {
  props: {
    data: Object,
    title: String,
    source: Number
  },
  data() {
    return {
      loading: false,
      document_type: DOCUMENT_FORM_TYPE
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onInitData()
    })
  },
  components: {
    Loading
  }, 
  methods: {
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const doc = new jsPDF('p','mm','a4')
        await this.onInitDocument(doc)
        doc.deletePage(doc.internal.getNumberOfPages())
        let element = document.getElementById(`form_pu-${this.source}`)
        if (element) {
          element.setAttribute("src", doc.output('bloburl'))
          setTimeout(() => {
            this.loading = false
            this.onIframePrint()
          }, 1500)
        }
      }, 1000)
    },
    onInitDocument(doc) {
      return new Promise(async(resolve, reject) => {
        try {
          doc.addFileToVFS(
            'THSarabunNew.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunNormal
          )
          // add custom font to file
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
          doc.setFont('THSarabunNew', 'normal')

          doc.addFileToVFS(
            'THSarabunNew Bold.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunBold
          )
          // add custom font to file
          doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
          doc.setFont('THSarabunNew', 'bold')

          const totalItems = this.data.factorys
          for (let index = 0; index < totalItems.length; index++) {
            const element = totalItems[index]
            let result = await this.createJspdfDocument(element, doc)
            doc = result
            doc.addPage()
          }

          resolve(doc)
        } catch (error) {
          reject(error)
        }
      })
    },
    createJspdfDocument(items, doc) {
      return new Promise(async (resolve, reject) => {
        const head = await this.onInitHeader(doc)
        const body = await this.onInitBody(items)
        doc.autoTable({
          theme: 'plain',
          margin: { top: 8, bottom: 0, left: 8, right: 8 },
          styles: {
            font: 'THSarabunNew'
          },
          headStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          bodyStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          footStyles: {
            valign: 'top',
            halign: 'center',
            minCellHeight: 0
          },
          showFoot: 'lastPage',
          head: head,
          body: [],
          willDrawCell (data) { }
        })

        const headTable = await this.onInitHeaderTable(doc)
        doc.autoTable({
          theme: 'plain',
          margin: { top: 8, bottom: 0, left: 8, right: 8 },
          startY: doc.lastAutoTable.finalY + 5,
          styles: {
            font: 'THSarabunNew'
          },
          headStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          bodyStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          footStyles: {
            valign: 'top',
            halign: 'center',
            minCellHeight: 0
          },
          showFoot: 'lastPage',
          head: headTable,
          body: body,
          willDrawCell (data) {}
        })

        const footer = await this.onInitFooter(doc)
        doc.autoTable({
          theme: 'plain',
          margin: { top: 8, bottom: 8, left: 8, right: 8 },
          startY: doc.lastAutoTable.finalY,
          styles: {
            font: 'THSarabunNew'
          },
          headStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          bodyStyles: {
            valign: 'top',
            minCellHeight: 0
          },
          footStyles: {
            valign: 'top',
            halign: 'center',
            minCellHeight: 0
          },
          showFoot: 'lastPage',
          foot: footer
        })
        resolve(doc)
      })
    },
    onInitHeaderTable(doc) {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: 'ลำดับ',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 14,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'รายการ',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'ยาว/เมตร',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 17,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'จำนวนแผ่น',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 19,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'เว้นชาย',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'รวมเมตร',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'ราคา/ม.',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'ค่าตัด',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'รวม',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitHeader(doc) {
      return new Promise(async (resolve, reject) => {
        const width = (doc.internal.pageSize.getWidth() - 16) / 10
        const head = [
          [
            {
              content: this.title,
              colSpan: 10,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 8
                },
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'วันที่',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.$options.filters.getDateShortFormatted(this.data.doc_date),
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              colSpan: 4,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.data?.storage,
              colSpan: 2,
              rowSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 20,
                fillColor: (this.source === this.document_type.PO)?[156, 163, 175]: [255, 255, 255],
                textColor: [255, 255, 255],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'No.',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.no,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'สาขา',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.branch,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'โป้ว',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.branch_pu,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
          ],
          [
            {
              content: 'ลอน',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.wave,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 3,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'หลังคาด้านบน',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.roof,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'ความหนา PU Foam',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.pu_foam,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'ราคา/หน่วย',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: 'ค่าตัดท้องเหล็ก',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: 'ราคารวมต่อหน่วย',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'Facing',
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.facing,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: width * 2,
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.data.unit_price,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.data.cutting_buttom_price,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            },
            {
              content: this.data.total_unit_price,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitBody(items) {
      return new Promise(async (resolve, reject) => {   
        const body = []
          let obj = {}
          let group = {}
          group = [
            {
              content: items?.name,
              colSpan: 9,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                  bottom: 0.1
                },
                fontSize: 16,
                fillColor: [255, 255, 255],
                textColor: [52, 79, 235],
                cellPadding: 2,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle'
              }
            }
          ]
          body.push(group)

          for (let i = 0; i < items['products'].length; i++) {
          const item = items['products'][i];
          obj = [
            {
              content: i + 1,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 14,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: item?.name,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: item?.length,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 17,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: item?.qty,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 19,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: item?.except,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: item?.total_length,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: item?.cost_per_m,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: item?.cutting_cost,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: item?.total,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 1.5,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ]
          body.push(obj)
          }

        let emptyBody = {}
        emptyBody = [
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 14,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 17,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 19,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 16,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 16,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 16,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 16,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 1.5,
              cellWidth: 16,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]

        if (body.length <= 16) {
          const number = 16 - body.length
          for (let index = 0; index < number; index++) {
            body.push(emptyBody)
          }
        }
        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {    
        const footer = []

        const note = [{
          content: `หมายเหตุ: ${this.data.note}`,
          colSpan: 10,
          styles: {
            lineWidth: 0.1,
            cellPadding: 2,
            cellWidth: 'auto',
            fontSize: 15,
            fillColor: [255, 255, 255],
            textColor: [15, 23, 42],
            fontStyle: 'italic',
            halign: 'left',
            valign: 'bottom'
          }
        }]
        footer.push(note)

        const remark = [{
          content: this.data.remark,
          colSpan: 10,
          styles: {
            lineWidth: 0.1,
            cellPadding: 2,
            cellWidth: 'auto',
            fontSize: 15,
            fillColor: [197, 250, 246],
            textColor: [15, 23, 42],
            fontStyle: 'italic',
            halign: 'center',
            valign: 'bottom'
          }
        }]
        footer.push(remark)

        const summary = [
        {
          content: 'ความยาวรวม',
          colSpan: 3,
          styles: {
            lineWidth: {
              left: 0.1, top: 0.1, bottom: 0.1
            },
            cellPadding: 2,
            cellWidth: 'auto',
            fontSize: 15,
            fillColor: [255, 255, 255],
            textColor: [15, 23, 42],
            fontStyle: 'bold',
            halign: 'right',
            valign: 'top'
          }
        },
        {
          content: this.data.grand_total_m,
          colSpan: 2,
          styles: {
            lineWidth: {
              top: 0.1, bottom: 0.1, left: 0.1, right: 0.1
            },
            cellPadding: 2,
            cellWidth: 35,
            fontSize: 15,
            fillColor: [250, 249, 197],
            textColor: [15, 23, 42],
            fontStyle: 'bold',
            halign: 'center',
            valign: 'top'
          }
        },
        {
          content: 'เมตร',
          colSpan: 1,
          styles: {
            lineWidth: {
              top: 0.1, bottom: 0.1
            },
            cellPadding: 2,
            cellWidth: 18,
            fontSize: 15,
            fillColor: [255, 255, 255],
            textColor: [15, 23, 42],
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top'
          }
        },
        {
          content: 'ยอดรวมทั้งสิ้น',
          colSpan: 2,
          styles: {
            lineWidth: {
              top: 0.1, bottom: 0.1
            },
            cellPadding: 2,
            cellWidth: 30,
            fontSize: 15,
            fillColor: [255, 255, 255],
            textColor: [15, 23, 42],
            fontStyle: 'bold',
            halign: 'right',
            valign: 'top'
          }
        },
        {
          content: this.data.grand_total,
          colSpan: 2,
          styles: {
            lineWidth: {
              top: 0.1, bottom: 0.1, left: 0.1, right: 0.1
            },
            cellPadding: 2,
            cellWidth: 35,
            fontSize: 15,
            fillColor: [250, 249, 197],
            fontStyle: 'bold',
            halign: 'right',
            valign: 'top'
          }
        }
      ]
      footer.push(summary)
      resolve(footer)
    })
  },
    onIframePrint() {
      let iFrame = document.getElementById(`form_pu-${this.source}`)
      iFrame.focus()
      iFrame.contentWindow.print()
    },
    onInitData() {}
  }
}